const albums = {
	"The Garden Collection": {
		cover: "/assets/singles-cover-250px.jpeg",
		restricted: false,
		songs: [
			{ name: "Red Bean", artist: "SireDontPlay", url: process.env.PUBLIC_URL +"/music/Singles/red  bean.mp3"},
			{ name: "The Garden Remix", artist: "SireDontPlay", url: process.env.PUBLIC_URL + "/music/Singles/The Garden Remix SireDontPlay.mp3" },
			{ name: "Red & Blue", artist: "SireDontPlay", url: process.env.PUBLIC_URL + "/music/Singles/redandblue final.mp3" },
			{ name: "Waifu", artist: "SireDontPlay", url: process.env.PUBLIC_URL + "/music/Singles/Waifu Final.mp3" },
			{ name: "Rare Traits", artist: "SireDontPlay", url: process.env.PUBLIC_URL + "/music/Singles/rare traits final.mp3" },
			{ name: "Zen", artist: "SireDontPlay", url: process.env.PUBLIC_URL + "/music/Singles/Zen Final.mp3" },
			{ name: "Puppet On A String", artist: "SireDontPlay", url: process.env.PUBLIC_URL + "/music/Singles/Puppet on a String.mp3" },
			{ name: "Top Tier", artist: "SireDontPlay", url: process.env.PUBLIC_URL + "/music/Singles/top tier.mp3" },
			{ name: "On Fire", artist: "SireDontPlay", url: process.env.PUBLIC_URL + "/music/Singles/On Fire.mp3" },
			{ name: "Mystery", artist: "SireDontPlay", url: process.env.PUBLIC_URL + "/music/Singles/mystery final final.mp3" }
		],
	},
	"Pushin B": {
		cover: "/assets/PushinB-cover-250px.jpeg",
		restricted: true,
		songs: [
			{ name: "Warrior", artist: "SireDontPlay", url: process.env.PUBLIC_URL + "/music/Pushin B/Warrior.mp3" },
			{ name: "Red Bean Pt 2", artist: "SireDontPlay", url: process.env.PUBLIC_URL + "/music/Pushin B/Red Bean Pt 2.mp3" },
			{ name: "!ttp", artist: "SireDontPlay", url: process.env.PUBLIC_URL + "/music/Pushin B/!ttp.mp3" },
			{ name: "Pushin B", artist: "SireDontPlay", url: process.env.PUBLIC_URL + "/music/Pushin B/Pushin B.mp3" },
			{ name: "Location TBA", artist: "SireDontPlay", url: process.env.PUBLIC_URL + "/music/Pushin B/Location TBA.mp3" },
			{ name: "Shuriken", artist: "SireDontPlay", url: process.env.PUBLIC_URL + "/music/Pushin B/Shuriken.mp3" }
		],
	},
	"BJMC": {
		cover: "/assets/BJMC-cover-250px.jpeg",
		restricted: true,
		songs: [
			{ name: "Black Jade", artist: "SireDontPlay", url: process.env.PUBLIC_URL + "/music/BJMC/Black Jade.mp3" },
			{ name: "Rule Number One", artist: "SireDontPlay", url: process.env.PUBLIC_URL + "/music/BJMC/Mahjong.mp3" },
		],
	},
	"Into The Garden": {
		cover: "/assets/Into-The-Garden-cover-250px.jpeg",
		restricted: true,
		songs: [
			{ name: "YUKI & BOBU", artist: "SireDontPlay (Prod. SHYY)", url: process.env.PUBLIC_URL + "/music/Into The Garden/1. YUKI & BOBU Prod SHYY.mp3" },
			{ name: "PRIVATE ISLAND", artist: "SireDontPlay (Prod. Kian)", url: process.env.PUBLIC_URL + "/music/Into The Garden/2. PRIVATE ISLAND Prod Kian.mp3" },
			{ name: "CUP OF COFFEE", artist: "SireDontPlay (Prod. SethGOTF)", url: process.env.PUBLIC_URL + "/music/Into The Garden/3. CUP OF COFFEE Prod SethGetOffTheField.mp3" },
			{ name: "BOSS UP", artist: "SireDontPlay (Prod. Skirmisher)", url: process.env.PUBLIC_URL + "/music/Into The Garden/4. BOSS UP Prod Skirmisher.mp3" },
			{ name: "NGL", artist: "SireDontPlay (Prod. Verky)", url: process.env.PUBLIC_URL + "/music/Into The Garden/5. NGL Prod Verky.mp3" },
			{ name: "ALLEY", artist: "SireDontPlay (Prod. JesusOfLitGang)", url: process.env.PUBLIC_URL + "/music/Into The Garden/6. ALLEY Prod JesusOfLitGang.mp3" },
			{ name: "FREE SPIRIT", artist: "SireDontPlay (Prod. JesusOfLitGang)", url: process.env.PUBLIC_URL + "/music/Into The Garden/7. FREE SPIRIT Prod JesusOfLitGang.mp3" },
			{ name: "TOKYO", artist: "SireDontPlay (Ft. 0xJasmin)", url: process.env.PUBLIC_URL + "/music/Into The Garden/8. TOKYO Ft. 0xJasmin.mp3" },
			{ name: "DOPE", artist: "SireDontPlay (Prod. SethGOTF)", url: process.env.PUBLIC_URL + "/music/Into The Garden/9. DOPE Prod SethGetOffTheField.mp3" },
			{ name: "DONT PLAY", artist: "SireDontPlay (Prod. SethGOTF)", url: process.env.PUBLIC_URL + "/music/Into The Garden/10. DONT PLAY Prod SethGetOffTheField.mp3" },
			{ name: "SWITCH SIDES. PT. 2", artist: "SireDontPlay (Prod. JesusOfLitGang)", url: process.env.PUBLIC_URL + "/music/Into The Garden/11. SWITCH SIDES. PT. 2 Ft. SethGetOffTheField & TinoTenToes Prod JesusOfLitGang.mp3" },
			{ name: "LESSON", artist: "SireDontPlay (Prod. JesusOfLitGang)", url: process.env.PUBLIC_URL + "/music/Into The Garden/12. LESSON Prod JesusOfLitGang.mp3" },
			{ name: "UH HUH", artist: "SireDontPlay (Ft. SethGOTF, Prod. Hari)", url: process.env.PUBLIC_URL + "/music/Into The Garden/13. UH HUH Ft. SethGetOffTheField Prod Hari.mp3" },
			{ name: "YEA YEA", artist: "SireDontPlay (Prod. SethGOTF)", url: process.env.PUBLIC_URL + "/music/Into The Garden/14. YEA YEA Prod SethGetOffTheField.mp3" },
			{ name: "PROOF OF SKATE", artist: "SireDontPlay (Prod. Laurent G)", url: process.env.PUBLIC_URL + "/music/Into The Garden/15. PROOF OF SKATE Prod. Laurent G.mp3" },
			{ name: "IKUZO (Remix)", artist: "SireDontPlay (Ft. Adrian Deng, Foxi)", url: process.env.PUBLIC_URL + "/music/Into The Garden/16. IKUZO (Remix) Ft. Adrian Deng & Foxi Prod Poszwixxx.mp3" },
		],
	},
	"Probably Yuki & Bobu": {
		cover: "/assets/Probably-Yuki-Bobu-cover-250px.png",
		restricted: false,
		songs: [
			{ name: "Probably Yuki & Bobu", artist: "SireDontPlay", url: process.env.PUBLIC_URL + "/music/Singles/Yuki & Bobu (House).mp3" },
		],
	},
	"Who's Active": {
		cover: "/assets/whos-active-cover-250px.jpeg",
		restricted: false,
		songs: [
			{ name: "Who's Active", artist: "SireDontPlay (Prod. Jazzmania)", url: process.env.PUBLIC_URL + "/music/Who's Active/Who's Active.mp3" },
		],
	},
	"Beanz": {
		cover: "/assets/beanz-cover-250px.jpeg",
		restricted: false,
		songs: [
			{ name: "Beanz", artist: "SireDontPlay (Prod. Jazzmania)", url: process.env.PUBLIC_URL + "/music/Beanz/Beanzfinal.mp3" },
		],
	},
	"Kyoto": {
		cover: "/assets/kyoto-cover-250px.jpeg",
		restricted: false,
		songs: [
			{ name: "Kyoto", artist: "SireDontPlay", url: process.env.PUBLIC_URL + "/music/Kyoto/kyoto.mp3" },
		],
	},
};

export default albums;