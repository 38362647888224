import { useEffect, useState } from 'react';
import './App.css';
import Player from './Player';
import Mint from './Mint';

function App() {
  const [walletAddress, setWalletAddress] = useState(null)
  const [nfts, setNFTs] = useState([])
  const [loading, setLoading] = useState(false)
  const [metaMaskInstalled, setMetaMaskInstalled] = useState(true)
  const [width, setWidth] = useState(window.innerWidth)

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);

  const connectWallet = async () => {
    if (typeof window.ethereum !== 'undefined') {
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      setWalletAddress(accounts[0]);
      setLoading(true);
    } else {
      setMetaMaskInstalled(false);
    }
  }

  const getNFTData = async () => {
    if(!walletAddress) return;

    const response = await fetch(`https://api.rarible.org/v0.1/items/byOwner?owner=ETHEREUM:${walletAddress}`);
    const data = await response.json();
    setNFTs(data.items.filter(nft => nft.blockchain === "ETHEREUM")
      .filter((nft) => {
        if (nft.creators.length > 0) {
          return nft.contract === "ETHEREUM:0x971fe612183d9ef27899a672d16cf66432b8b02d";
        }
      })
    );
    setLoading(false);
  }

  useEffect(() => {
    getNFTData()
  }, [walletAddress]);

  return (
    <div className='redbean'>
      {/* <video autoPlay muted loop className={(nfts.length > 0  && width > 768) ? 'animated-bg' : 'hidden'}>
        <source src={process.env.PUBLIC_URL + "/assets/redBeanAnimated.mp4"} type="video/mp4" />
      </video> */}
      <div className={'mm-notification ' + (metaMaskInstalled ? 'hidden' : '')}>
        MetaMask is not installed on your browser. Install the MetaMask Chrome extension or use the MetaMask mobile app.
      </div>
      <div>
        <Player width={width} walletAddress={walletAddress} loading={loading} /> 
        {/* <Mint wallet={walletAddress} window={window} walletAddress={walletAddress} /> */}
        { (walletAddress && !loading) &&
          <div className={'connected ' + (width <= 768 ? 'hidden' : '')}>
            Connected 
          </div>
        }
        
      </div>
      { !walletAddress &&
        <div className='connect' onClick={connectWallet}>
          <button className='mm-button'></button>
          Connect
        </div>
      }
      { loading &&  <i className='fa-solid fa-cog fa-spin fa-6x loading icon'></i> }
    </div>
  );
}

export default App;