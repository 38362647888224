import { useState, useRef, useCallback, useEffect } from 'react';
import albums from './music';
import React from 'react';

const Player = ({ width, walletAddress, loading }) => {
	const openSeaCollectionUrl = 'https://opensea.io/collection/pushin-b';
	const spotifyUrl = 'https://open.spotify.com/artist/01l9ER5WeUWKsHBEcyzSK7?si=F5Hf7985S4mprHwXG2BIUQ';
	const applemusicUrl = 'https://music.apple.com/us/artist/siredontplay/1491791350';
	const [autoPlay, setAutoPlay] = useState(false);
	const [currentSong, setCurrentSong] = useState(0);
	const [paused, setPaused] = useState(true);
	const [activeAlbum, setActiveAlbum] = useState('The Garden Collection');
	const audio = useRef(new Audio(albums[activeAlbum]['songs'][currentSong]['url']));
	const [pushinBs, setPushinBs] = useState([]);
	const [pushinBsLoaded, setPushinBsLoaded] = useState(false);
	const [isDownloadable, setIsDownloadable] = useState(false);
	
	audio.current.onended = () => {
		forward();
	};

	const playMusic = useCallback(
		() => {
			audio.current.play();
			setPaused(false);
		},
		[currentSong, activeAlbum, audio]
	);

	const pauseMusic = useCallback(
		() => {
			audio.current.pause();
			setPaused(true);
		},
		[]
	);

	const toggleLoop = useCallback(
		() => {
			audio.current.loop = !audio.current.loop;
			setAutoPlay(audio.current.loop);
		},
		[autoPlay]
	);

	const forward = useCallback(
		() => {
			const nextSong = (currentSong+1) % albums[activeAlbum]['songs'].length;
			pauseMusic();
			audio.current = new Audio(albums[activeAlbum]['songs'][nextSong]['url']);
			setCurrentSong(nextSong);
			playMusic();
		},
		[currentSong, activeAlbum, audio]
	);

	const backward = useCallback(
		() => {
			const prevSong = currentSong === 0 ? albums[activeAlbum]['songs'].length - 1 : Math.abs((currentSong-1) % albums[activeAlbum]['songs'].length);
			pauseMusic();
			audio.current = new Audio(albums[activeAlbum]['songs'][prevSong]['url']);
			setCurrentSong(prevSong);
			playMusic();
		},
		[currentSong, activeAlbum, audio]
	);

	const toggleAlbum = useCallback(() => {
		if (pushinBs.length > 0) { 
			if (activeAlbum === 'The Garden Collection') {
				setActiveAlbum('Pushin B'); 
			} else if (activeAlbum === 'Pushin B') {
				setActiveAlbum('BJMC'); 
			} else if (activeAlbum === 'BJMC') {
				setActiveAlbum('Into The Garden'); 
			} else if (activeAlbum === 'Into The Garden') {
				setActiveAlbum('Probably Yuki & Bobu'); 
			} else if (activeAlbum === 'Probably Yuki & Bobu') {
				setActiveAlbum("Who's Active"); 
			} else if (activeAlbum === "Who's Active") {
				setActiveAlbum("Beanz"); 
			} else if (activeAlbum === 'Beanz') {
				setActiveAlbum("Kyoto"); 
			} else {
				setActiveAlbum('The Garden Collection'); 
			}
		} else {
			if (activeAlbum === 'Into The Garden') {
				setActiveAlbum('The Garden Collection'); 
			} else if (activeAlbum === 'The Garden Collection') {
				setActiveAlbum('Probably Yuki & Bobu'); 
			} else if (activeAlbum === 'Probably Yuki & Bobu') {
				setActiveAlbum("Who's Active"); 
			} else if (activeAlbum === "Who's Active") {
				setActiveAlbum("Beanz"); 
			} else if (activeAlbum === 'Beanz') {
				setActiveAlbum("Kyoto"); 
			} else {
				setActiveAlbum('Into The Garden'); 
			}
		}
		setCurrentSong(0);
	}, [currentSong, activeAlbum, audio]);

	const changeAlbum = useCallback((name) => () => {
			setActiveAlbum(name);
			setCurrentSong(0);
	}, [currentSong, activeAlbum, audio]);

	useEffect(() => {
		pauseMusic();
		audio.current = new Audio(albums[activeAlbum]['songs'][currentSong]['url']);
		return () => {
			setPaused(true);
		}
	}, [activeAlbum]);

	const getPushinBData = async () => {
    if(!walletAddress) return;

    const response = await fetch(`https://api.rarible.org/v0.1/items/byOwner?owner=ETHEREUM:${walletAddress}`);
    const data = await response.json();
    setPushinBs(data.items.filter(nft => nft.blockchain === "ETHEREUM")
      .filter(nft => nft.contract === "ETHEREUM:0x971fe612183d9ef27899a672d16cf66432b8b02d")
    );
		setPushinBsLoaded(true);
  }

	const getDownloadable = async () => {
		pushinBs.filter((nft) => 
			nft.meta.attributes.filter((attr) => {
				if (attr['key'] === 'Song') {
					setIsDownloadable(attr['value'] === albums[activeAlbum]['songs'][currentSong]['name']);
				}
			}
		))
	}

	const setFeaturedSong = useCallback(
		() => {
			setActiveAlbum("Kyoto");
			setCurrentSong(0);
			audio.current.play();
			setPaused(false);
		},
		[]
	);

	const playAndHide = useCallback(
		() => {
			setActiveAlbum("Kyoto");
			setCurrentSong(0);
			audio.current.play();
			setPaused(false);
		},
		[]
	);

	useEffect(() => {
		getDownloadable();
		return () => setIsDownloadable(false);
	}, [currentSong, activeAlbum]);

  useEffect(() => {
    getPushinBData();
		return () => setPushinBs([]);
  }, [walletAddress]);

	// Rendered components

	const webAlbums = Object.keys(albums).map(key => {
		const publicAlbums = ["The Garden Collection", "Into The Garden", "Probably Yuki & Bobu", "Who's Active", "Beanz", "Kyoto"]
		const isUnlocked = pushinBs.length > 0
		return <div key={key} className={ 'album-frame ' + (publicAlbums.indexOf(key) !== -1 || isUnlocked ? '' : 'hidden' )}>
			<div className={'album clickable ' + (activeAlbum === key ? 'active-album' : '') } 
				onClick={changeAlbum(key)}>
				<img src={albums[key]['cover']} alt={key} width="80px" height="80px" />
				{key}
			</div>
		</div>
	});

	const mobileAlbums = Object.keys(albums).map(key => {
		return <div key={key} className={'album-container-mobile ' + ((activeAlbum === key) ? '' : 'hidden')}>
			<img src={albums[key]['cover']} alt={key} width="70px" height="70px" />
		</div>
	});
	
	return (
		<div>
			<div className={'logo ' + (width <= 480 ? 'hidden' : '')}></div>
			<div className='player-container'>
				<i className={'fa-solid fa-play fa-dynamic icon play-icon clickable ' + (paused ? '' : 'hidden') } 
					onClick={playMusic}></i>
				<i className={'fa-solid fa-pause fa-dynamic icon pause-icon clickable ' + (paused ? 'hidden' : '')} 
					onClick={pauseMusic}></i>
				<i className={'fa-solid fa-repeat fa-dynamic icon loop-icon clickable ' + (autoPlay ? 'active-icon' : '')}
					onClick={toggleLoop}></i>
				<span className='song-text'>{ albums[activeAlbum]['songs'][currentSong]['name'] }</span>
				<span className={'author-text'}>{ albums[activeAlbum]['songs'][currentSong]['artist'] }</span>
				<i className='fa-solid fa-forward fa-dynamic icon forward-icon clickable' onClick={forward}></i>
				<i className='fa-solid fa-backward fa-dynamic icon backward-icon clickable' onClick={backward}></i>
			{ (isDownloadable) &&
				<a className='download-icon' href={albums[activeAlbum]['songs'][currentSong]['url']} download>
					<i className='fa-solid fa-cloud-arrow-down fa-dynamic icon'></i>
				</a>
			}
			</div>
			{ width > 480 &&
				<div className='album-container'>
					{webAlbums}
				</div>
			}
			{ width > 480 &&
				<div className={'feature-container ' + (paused === false ? 'hidden' : '')}>
					<img src={albums["Kyoto"]['cover']} alt="Who's Active" width="80px" height="80px" />
					<div className='feature-song-text'><span className='icon'>NEW</span> Kyoto</div>
					<div className={'feature-play-button clickable ' + (activeAlbum === "Kyoto" ? 'hidden' : '')} onClick={setFeaturedSong}>Take me there</div>
					<div className={'feature-play-button clickable ' + (activeAlbum === "Kyoto" ? '' : 'hidden')} onClick={playAndHide}>Let me hear</div>
				</div>
			}
			{ width > 480 &&
			<>
				<a className='opensea' href={openSeaCollectionUrl} target="_blank" rel="noreferrer">
					<img src={process.env.PUBLIC_URL + '/assets/opensea-logo.png'} alt="Opensea" width="60px" height="60px" />
				</a>
				<a className='spotify' href={spotifyUrl} target="_blank" rel="noreferrer">
					<img src={process.env.PUBLIC_URL + '/assets/spotify-logo.png'} alt="Spotify" width="60px" height="60px" />
				</a>
				<a className='applemusic' href={applemusicUrl} target="_blank" rel="noreferrer">
					<img src={process.env.PUBLIC_URL + '/assets/applemusic-logo.png'} alt="Apple Music" width="60px" height="60px" />
				</a>
			</>
			}
			{ (width <= 480) && mobileAlbums }
			{ (width <= 480) &&
				<i className='fa-solid fa-clone fa-dynamic icon switch-icon' onClick={toggleAlbum}></i>
			}
			{ (width <= 480) &&
				<>
					<a className='opensea-mobile' href={openSeaCollectionUrl} target="_blank" rel="noreferrer">
						<img src={process.env.PUBLIC_URL + '/assets/opensea-logo.png'} alt="Opensea" width="60px" height="60px" />
					</a>
					<a className='spotify-mobile' href={spotifyUrl} target="_blank" rel="noreferrer">
						<img src={process.env.PUBLIC_URL + '/assets/spotify-logo.png'} alt="Spotify" width="60px" height="60px" />
					</a>
					<a className='applemusic-mobile' href={applemusicUrl} target="_blank" rel="noreferrer">
						<img src={process.env.PUBLIC_URL + '/assets/applemusic-logo.png'} alt="Spotify" width="60px" height="60px" />
					</a>
				</>
			}
		</div>
	)
}

export default Player